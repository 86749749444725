

function Footer() {
    return (
        <footer>
      <p>&copy; 2023 Caioricardolp. Todos os direitos reservados.</p>
    </footer>
    );
  }
  
  export default Footer;
  